import { scrollTo } from "./smoothScrollToElement";

export const scrollToElementIfNotVisible = (
  selector: string,
  offset = 0
): void => {
  const element = document.querySelector(selector) as HTMLElement;

  if (!element) return;

  const { top, bottom } = element.getBoundingClientRect();
  const isElementVisible = top >= 0 && bottom <= window.innerHeight;

  if (!isElementVisible) {
    const elementTop = top + window.scrollY;
    const elementBottom = elementTop + element.offsetHeight;
    const viewportTop = window.scrollY;
    const viewportBottom = viewportTop + window.innerHeight;

    if (elementTop < viewportTop || elementBottom > viewportBottom) {
      scrollTo(element, offset);
    }
  }
};
