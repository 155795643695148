import { SwitchItemHeader } from "@/types/SwitchItemHeader";

export const headerTabs: SwitchItemHeader[] = [
  {
    label: "Developers & Land Sales",
    value: "developers-and-land-sales",
    path: "/",
  },
  {
    label: "Builders",
    value: "builders",
    path: "/builders",
  },
  {
    label: "Plans",
    value: "plans",
    path: "/plans",
    hidden: true,
  },
];
