import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24c3f1c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feature" }
const _hoisted_2 = { class: "photo-wr" }
const _hoisted_3 = ["data-aos", "src"]
const _hoisted_4 = ["data-aos"]
const _hoisted_5 = ["data-aos"]
const _hoisted_6 = {
  class: "info-label",
  "data-aos-duration": "500",
  "data-aos-once": "true"
}
const _hoisted_7 = {
  class: "info-title",
  "data-aos-once": "true"
}
const _hoisted_8 = ["data-aos"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "photo",
        "data-aos": $setup.props.animationImg,
        "data-aos-once": "true",
        src: require(`@/assets/images/landing/${$setup.props.image}`),
        alt: "initial deposit"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        class: "box-shadow",
        "data-aos": $setup.props.animationImg,
        "data-aos-once": "true"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", {
      class: "info",
      "data-aos": $setup.props.animationText,
      "data-aos-once": "true"
    }, [
      _createElementVNode("div", _hoisted_6, _toDisplayString($setup.props.label), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString($setup.props.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.paragraphs, (paragraph) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "info-text",
          "data-aos": $setup.paragraphAnimation.name,
          "data-aos-once": "true"
        }, _toDisplayString(paragraph), 9, _hoisted_8))
      }), 256)),
      _renderSlot(_ctx.$slots, "button", {}, undefined, true)
    ], 8, _hoisted_5)
  ]))
}