import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b8d01ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header-mobile"
}
const _hoisted_2 = { class: "top-row" }
const _hoisted_3 = { class: "wrapper-mobile-switcher" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.activeHeaderTab?.value)
    ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "logo",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.router.push('/')))
          }),
          _createElementVNode("button", {
            class: "link-primary",
            onClick: $setup.onSingIn
          }, "Sign In")
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["SwitchComponent"], {
            class: "landing-mobile-header",
            list: $setup.visibleHeaderTabs,
            modelValue: $setup.activeHeaderTab,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => (($setup.activeHeaderTab) = $event)),
              $setup.onChangeHeaderTab
            ]
          }, null, 8, ["list", "modelValue"])
        ])
      ]))
    : _createCommentVNode("", true)
}